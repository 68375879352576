<script lang="ts" context="module">
    import type { ErrorLoad } from '@sveltejs/kit';
    export const load: ErrorLoad = ({ error }) => {
        return {
            props: {
                error,
            },
        };
    };
</script>

<script lang="ts">
    export let error: Error;
</script>

<div>
    <p>{error.name}</p>
    <p>{error.message}</p>
    <p>{error.stack}</p>
</div>

<style>
    div {
        max-width: 100%;
        font-size: 11px;
    }
</style>
